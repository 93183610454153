:root {
  /* Font Family */
  --default-font-family:
    'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;
  --code-font-family: Hack, 'D2Coding', monospace;
  --header-font-family: Staatliches, cursive;

  /* Default */
  --default-text-color-hue: 0deg;
  --default-text-color-saturation: 0%;
  --default-text-color-luminosity: 6%;
  --default-text-color: hsl(
    var(--default-text-color-hue) var(--default-text-color-saturation) var(--default-text-color-luminosity)
  );
  --default-font-size: 1.2rem;
  --default-line-height: 1.35;

  /* Brand */
  --brand-color-hue: 220deg;
  --brand-color-saturation: 50%;
  --brand-color-luminosity: 40%;
  --brand-color: hsl(var(--brand-color-hue) var(--brand-color-saturation) var(--brand-color-luminosity));
  --brand-color-light1: hsl(
    var(--brand-color-hue) var(--brand-color-saturation) calc(var(--brand-color-luminosity) + 10%)
  );
  --brand-color-light2: hsl(
    var(--brand-color-hue) var(--brand-color-saturation) calc(var(--brand-color-luminosity) + 20%)
  );
  --brand-color-dark1: hsl(
    var(--brand-color-hue) var(--brand-color-saturation) calc(var(--brand-color-luminosity) - 10%)
  );
  --brand-color-dark2: hsl(
    var(--brand-color-hue) var(--brand-color-saturation) calc(var(--brand-color-luminosity) - 20%)
  );
  --brand-color-o5: hsl(var(--brand-color-hue) var(--brand-color-saturation) var(--brand-color-luminosity) / 0.5);

  /* Mono */
  --mono-color-hue: 0deg;
  --mono-color-saturation: 0%;
  --mono-color-luminosity: 38%;
  --mono-color: hsl(var(--mono-color-hue) var(--mono-color-saturation) var(--mono-color-luminosity));
  --mono-color-light1: hsl(var(--mono-color-hue) var(--mono-color-saturation) calc(var(--mono-color-luminosity) + 10%));
  --mono-color-light2: hsl(var(--mono-color-hue) var(--mono-color-saturation) calc(var(--mono-color-luminosity) + 20%));
  --mono-color-light3: hsl(var(--mono-color-hue) var(--mono-color-saturation) calc(var(--mono-color-luminosity) + 30%));
  --mono-color-light4: hsl(var(--mono-color-hue) var(--mono-color-saturation) calc(var(--mono-color-luminosity) + 40%));
  --mono-color-light5: hsl(var(--mono-color-hue) var(--mono-color-saturation) calc(var(--mono-color-luminosity) + 50%));
  --mono-color-dark1: hsl(var(--mono-color-hue) var(--mono-color-saturation) calc(var(--mono-color-luminosity) - 10%));
  --mono-color-dark2: hsl(var(--mono-color-hue) var(--mono-color-saturation) calc(var(--mono-color-luminosity) - 20%));

  /* Spacing */
  --spacing-unit-1: 0.05rem;
  --spacing-unit-2: calc(var(--spacing-unit-1) * 2);
  --spacing-unit-3: calc(var(--spacing-unit-2) * 2);
  --spacing-unit-4: calc(var(--spacing-unit-3) * 2);
  --spacing-unit-5: calc(var(--spacing-unit-4) * 2);
  --spacing-unit-6: calc(var(--spacing-unit-5) * 2);
  --spacing-unit-7: calc(var(--spacing-unit-6) * 2);

  /* Small Text */
  --small-text-size-1: calc(var(--default-font-size) * 0.9);
  --small-text-size-2: calc(var(--default-font-size) * 0.8);
  --small-text-size-3: calc(var(--default-font-size) * 0.7);

  /* Body */
  --body-background-color: #fdfdfd;

  /* Code */
  --code-font-size: 1rem;
  --code-text-color: #fff;
  --code-background-color: #1e1e1e;

  /* Code Block */
  --code-block-font-size: 1rem;

  /* Header */
  --header-nav-link-font-size: 1.4rem;
  --header-nav-padding-size: calc(var(--spacing-unit-5) * 1.5);
  --header-link-color: #fff;

  --list-mode-header-padding-bottom-size: 3rem;
  --page-mode-header-padding-bottom-size: 2rem;
  --header-padding-bottom-size: var(--page-mode-header-padding-bottom-size);

  /* Blog Logo */
  --list-mode-blog-logo-height: 5rem;
  --page-mode-blog-logo-height: 3rem;

  --blog-logo-color: #fff;
  --blog-logo-height: var(--page-mode-blog-logo-height);

  --list-mode-blog-logo-margin-size: calc(var(--list-mode-blog-logo-height) * 0.7);
  --page-mode-blog-logo-margin-size: calc(var(--page-mode-blog-logo-height) * 0.5);
  --blog-logo-margin-size: var(--page-mode-blog-logo-height);

  --blog-logo-border-width: calc(var(--blog-logo-height) / 5);
  --blog-logo-border: var(--blog-logo-border-width) solid var(--blog-logo-color);

  --list-mode-blog-logo-vertical-padding-size: var(--spacing-unit-3);
  --page-mode-blog-logo-vertical-padding-size: var(--spacing-unit-5);
  --blog-logo-vertical-padding-size: var(--page-mode-blog-logo-vertical-padding-size);
  --blog-logo-padding: var(--blog-logo-vertical-padding-size) var(--spacing-unit-6);

  /* Body */
  --list-mode-body-max-width: calc(950px - var(--spacing-unit-4));
  --page-mode-body-max-width: calc(950px - var(--spacing-unit-3));
  --body-max-width: var(--page-mode-body-max-width);
  --list-mode-body-margin: 3rem auto;
  --page-mode-body-margin: 2rem auto;
  --body-margin: var(--page-mode-body-margin);
  --body-padding: 0;

  /* Post */
  --post-margin-block-size: 1rem;
  --post-margin-block-size-up1: calc(var(--post-margin-block-size) * 2);
  --post-margin-block-size-down1: calc(var(--post-margin-block-size) / 2);
  --post-h4-font-size: calc(var(--default-font-size) * 1.15);
  --post-h3-font-size: calc(var(--post-h4-font-size) * 1.15);
  --post-h2-font-size: calc(var(--post-h3-font-size) * 1.15);
  --post-title-border-left: var(--spacing-unit-4) solid var(--brand-color-light1);
  --post-title-font-size: calc(var(--post-h2-font-size) * 1.38);
  --post-header-border-bottom: var(--spacing-unit-2) solid var(--mono-color-light3);
  --post-header-block-end-margin-size: var(--spacing-unit-7);
  --post-header-block-end-padding-size: var(--spacing-unit-5);
}

@media screen and (max-width: 1024px) {
  :root {
    --body-padding: 0 var(--spacing-unit-5);
    --list-mode-blog-logo-height: min(calc(calc(100vw / 8) - var(--spacing-unit-6)), 5rem);
    --page-mode-blog-logo-height: min(calc(calc(100vw / 8) - var(--spacing-unit-6)), 3rem);
  }
}
@media screen and (max-width: 640px) {
  :root {
    --post-h4-font-size: var(--default-font-size);
    --post-h3-font-size: calc(var(--post-h4-font-size) * 1.1);
    --post-h2-font-size: calc(var(--post-h3-font-size) * 1.1);
    --post-title-font-size: calc(var(--post-h3-font-size) * 1.21);
    --body-padding: 0 var(--spacing-unit-5);
  }
}
* {
  box-sizing: border-box;
}
body,
ul,
ol,
dl,
p {
  margin: 0;
  padding: 0;
}
body {
  background-color: var(--body-background-color);
  color: var(--default-text-color);
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  font-weight: 300;
  line-height: var(--default-line-height);
}
code {
  font-family: var(--code-font-family);
  font-size: var(--code-font-size);
}
code:not([class]) {
  font-size: var(--small-text-size-2);
  padding: var(--spacing-unit-3);
  background: var(--code-background-color);
  color: var(--code-text-color);
}

body > div > header[data-is-list-page] {
  --header-padding-bottom-size: var(--list-mode-header-padding-bottom-size);
}
body > div > header {
  background-color: var(--brand-color);
  display: flex;
  flex-direction: column;
  font-family: var(--header-font-family);
  padding-bottom: var(--header-padding-bottom-size);
}
body > div > main[data-is-list-page] {
  --body-margin: var(--list-mode-body-margin);
  --body-max-width: var(--list-mode-body-max-width);
}
body > div > main {
  margin: var(--body-margin);
  max-width: var(--body-max-width);
  padding: var(--body-padding);
}
body > div > footer {
  background-color: var(--mono-color-light5);
  font-size: var(--small-text-size-1);
  margin-top: 6rem;
  padding: 3rem 0;
  text-align: center;
}
body > div > footer > p:not(:last-of-type) {
  margin-block-end: var(--spacing-unit-4);
}
#no-ai {
  font-size: var(--small-text-size-3);
}
:is(ol, ul).inline {
  display: inline;
  list-style-type: none;
}
:is(ol, ul).inline > li {
  display: inline-block;
}
:is(ol, ul).inline > li:not(:last-of-type) {
  margin-bottom: var(--spacing-unit-4);
  margin-right: var(--spacing-unit-3);
}
:is(ol, ul).inline > li:not(:last-of-type)::after {
  content: ',';
}

.blog-header nav {
  align-self: flex-end;
  padding: var(--header-nav-padding-size);
}
.blog-header ul {
  list-style-type: none;
}
.blog-header li {
  display: inline-block;
  margin-right: var(--spacing-unit-5);
}
.blog-header li a {
  color: var(--header-link-color);
  font-size: var(--header-nav-link-font-size);
  text-decoration: none;
}
.blog-header[data-is-list-page] .site-title {
  --blog-logo-margin-size: var(--list-mode-blog-logo-margin-size);
}
.blog-header h1.site-title {
  align-self: center;
  color: var(--blog-logo-color);
  font-size: var(--default-font-size);
  margin-top: var(--blog-logo-margin-size);
}
.blog-header[data-is-list-page] .site-title a {
  --blog-logo-height: var(--list-mode-blog-logo-height);
  --blog-logo-vertical-padding-size: var(--list-mode-blog-logo-vertical-padding-size);
}
.blog-header .site-title a {
  border: var(--blog-logo-border);
  display: block;
  line-height: 0;
  padding: var(--blog-logo-padding);
}
.blog-header .site-title :is(a, a:hover, span) {
  color: var(--blog-logo-color);
}
.blog-header .site-title :is(svg, span) {
  display: inline-block;
  fill: #fff;
  height: var(--blog-logo-height);
}
.blog-header .site-title span {
  line-height: var(--blog-logo-height);
  text-align: center;
  text-transform: uppercase;
  width: calc(var(--blog-logo-height) * 8.61);
}

main a:not([aria-hidden], [aria-label], [aria-describedby]) {
  color: var(--brand-color);
  text-decoration: underline var(--brand-color-o5) var(--spacing-unit-2);
  text-decoration-skip-ink: none;
  transition: color 200ms ease-in-out;
}
main a:not([aria-hidden], [aria-label], [aria-describedby]):hover {
  color: var(--brand-color-dark2);
}

main .post-list {
  list-style-type: none;
}
main .post-list > li {
  display: grid;
  grid-template-areas:
    'time'
    'link'
    'subtitle'
    'desc';
  margin-block-end: var(--spacing-unit-6);
  margin-left: var(--spacing-unit-6);
}
main .post-list a {
  color: var(--brand-color-dark1);
  font-size: var(--post-h2-font-size);
  font-weight: bold;
  grid-area: link;
  text-decoration: none;
}
main .post-list a:hover {
  color: var(--brand-color);
}
main .post-list p:has(time) {
  color: var(--mono-color-dark1);
  font-size: var(--small-text-size-2);
  grid-area: time;
}
main .post-list p:not(.subtitle, :has(time)) {
  color: var(--mono-color);
  font-size: var(--small-text-size-1);
  grid-area: desc;
}
main .post-list p.subtitle {
  color: var(--mono-color-dark2);
  font-size: var(--default-font-size);
  font-weight: bold;
  grid-area: subtitle;
}
main nav.paginator {
  margin-top: 5rem;
  text-align: center;
}
main nav.paginator ul {
  list-style-type: none;
}
main nav.paginator li {
  border: var(--spacing-unit-2) solid var(--mono-color-light3);
  display: inline-block;
  padding: var(--spacing-unit-2) var(--spacing-unit-5);
  transition: background-color 200ms ease-in-out;
}
main nav.paginator li:first-of-type {
  border-bottom-left-radius: var(--spacing-unit-4);
  border-top-left-radius: var(--spacing-unit-4);
}
main nav.paginator li:last-of-type {
  border-bottom-right-radius: var(--spacing-unit-4);
  border-top-right-radius: var(--spacing-unit-4);
}
main nav.paginator li:not(:last-of-type) {
  border-right: 0;
}
main nav.paginator a {
  font-size: var(--small-text-size-1);
  text-decoration: none;
  transition: color 200ms ease-in-out;
}
main nav.paginator :is(li:hover, li:has(a:hover)) {
  background-color: var(--brand-color-dark1);
}

main nav.paginator :is(li:hover a, li:has(a:hover)) {
  color: #fff;
}

main header {
  border-bottom: var(--post-header-border-bottom);
  margin-block-end: var(--post-header-block-end-margin-size);
  padding-block-end: var(--post-header-block-end-padding-size);
}
main header > h2 {
  border-left: var(--post-title-border-left);
  font-size: var(--post-title-font-size);
  line-height: 1.7;
  padding-left: var(--spacing-unit-5);
}
main header aside {
  text-align: right;
}
main header .meta {
  column-gap: var(--spacing-unit-4);
  display: grid;
  font-size: var(--small-text-size-1);
  grid-template-columns: minmax(0, auto) 1fr;
  row-gap: var(--spacing-unit-4);
}
main header .meta dt {
  text-align: right;
}
main header .meta dt::after {
  content: ':';
}
main header .meta dd {
  margin: 0;
}
section.articleBody :is(h2, p, dl, ul, ol, hr, pre, div.remark-code-container) {
  margin-block-end: var(--post-margin-block-size);
}

section.articleBody :is(h3, h4) {
  margin-block-end: var(--post-margin-block-size-down1);
}

section.articleBody :is(h2, h3, h4) {
  font-weight: bold;
}

section.articleBody h2 {
  border-bottom: var(--spacing-unit-1) dotted var(--mono-color);
  font-size: var(--post-h2-font-size);
}

section.articleBody h3 {
  font-size: var(--post-h3-font-size);
}

section.articleBody h4 {
  font-size: var(--post-h4-font-size);
}
section.articleBody :is(p, dl, ol, ul, pre, div.remark-code-container) + :is(h2, h3) {
  margin-block-start: var(--post-margin-block-size-up1);
}
section.articleBody :is(ol, ul, dd) {
  padding-left: var(--spacing-unit-7);
}
section.articleBody li > :is(ol, ul) {
  margin-block-end: 0;
}
section.articleBody img {
  max-width: 100%;
  height: auto;
}

section.articleBody blockquote {
  border-left: var(--spacing-unit-3) solid var(--brand-color);
  color: var(--mono-color-dark1);
  font-style: italic;
  padding-left: var(--spacing-unit-6);
}
section.articleBody blockquote:last-child {
  margin-block-end: 0;
}
section.articleBody p:has(img) {
  max-width: 100%;
  text-align: center;
}
section.articleBody div.remark-code-container {
  background: #1d1f21;
  border-radius: var(--spacing-unit-5);
  padding: var(--spacing-unit-5) 0;
}
section.articleBody div.remark-code-container > pre {
  margin-block: var(--spacing-unit-6);
}
section.articleBody div.remark-code-title {
  border-block-end: var(--spacing-unit-2) solid var(--mono-color-light1);
  color: var(--mono-color-light5);
  font-family: var(--code-font-family);
  font-size: var(--code-font-size);
  margin-block-start: var(--spacing-unit-4);
  padding-block-end: var(--spacing-unit-5);
  padding-inline: var(--spacing-unit-6);
}
section.articleBody code.hljs {
  font-size: var(--code-font-size);
  padding: 0;
}
section.articleBody .code-line {
  border-inline-start: var(--spacing-unit-5) solid transparent;
  display: inline-block;
  min-width: 100%;
  padding-inline: var(--spacing-unit-4);
}
section.articleBody .numbered-code-line::before {
  border-right: 1px solid var(--mono-color-light2);
  color: var(--mono-color-light2);
  content: attr(data-line-number);
  display: inline-block;
  font-weight: normal;
  margin-left: var(--spacing-unit-4);
  margin-right: var(--spacing-unit-5);
  padding-right: var(--spacing-unit-5);
  text-align: right;
  width: 1.5rem;
}
section.articleBody .highlighted-code-line {
  /* https://github.com/w0ng/vim-hybrid/blob/master/colors/hybrid.vim */
  background-color: #373b41;
  border-inline-start-color: var(--brand-color-light2);
}
section.articleBody .footnotes {
  margin-block-start: var(--spacing-unit-7);
  padding-block-start: var(--spacing-unit-5);
}
section.articleBody .footnotes li {
  word-break: break-all;
}

@supports (overflow-x: auto) {
  section.articleBody pre {
    overflow-x: auto;
  }
  section.articleBody p:has(img) {
    overflow-x: auto;
  }
}

main footer .related {
  border-top: var(--spacing-unit-1) dashed var(--mono-color-dark1);
  margin-block-start: var(--spacing-unit-7);
  padding-left: var(--spacing-unit-5);
}
main footer .related h3 {
  color: var(--mono-color-dark1);
  margin-block-end: var(--spacing-unit-4);
}
main footer .related ul {
  list-style: none;
}
main footer .related li {
  color: var(--mono-color);
  margin-block-end: var(--spacing-unit-4);
  margin-left: var(--spacing-unit-5);
}
main footer .related a {
  margin-left: var(--spacing-unit-4);
}
main a.autohead {
  color: var(--mono-color);
  font-size: var(--small-text-size-1);
  margin-left: var(--spacing-unit-5);
}
main a.autohead:hover {
  color: var(--mono-color-dark2);
}
main :is(sup a[data-footnote-ref], .footnotes a[data-footnote-backref]) {
  color: var(--brand-color);
  font-size: var(--small-text-size-2);
  margin-left: var(--spacing-unit-3);
  text-decoration: none;
}
main :is(sup a[data-footnote-ref], .footnotes a[data-footnote-backref])::before {
  content: '[';
}
main :is(sup a[data-footnote-ref], .footnotes a[data-footnote-backref])::after {
  content: ']';
}
main :is(a, button).share {
  background-color: var(--share-background-color, initial);
  border: 0;
  border-radius: var(--spacing-unit-4);
  color: var(--share-text-color, initial);
  cursor: pointer;
  display: inline-block;
  font-family: var(--default-font-family);
  font-size: var(--small-text-size-2);
  font-weight: normal;
  line-height: var(--small-text-size-2);
  margin: var(--spacing-unit-4);
  padding: var(--spacing-unit-5);
  text-decoration: none;
  text-transform: none;
  transition: box-shadow 150ms ease-out;
}
main :is(a, button).share:hover {
  color: var(--share-text-color, initial);
  box-shadow:
    0 0 0 var(--spacing-unit-3) #fff,
    0 0 0 var(--spacing-unit-4) var(--share-background-color, #000);
}
main :is(a, button).share > svg {
  margin-right: var(--spacing-unit-3);
}
main button.share:not([data-variant]) {
  --share-background-color: var(--brand-color-light1);
  --share-text-color: #fff;
}
main button.share:not([data-variant]) > svg {
  margin-right: var(--spacing-unit-4);
}
main a.share[data-variant='facebook'] {
  --share-background-color: #3b5998;
  --share-text-color: #fff;
}
main a.share[data-variant='twitter'] {
  --share-background-color: #55acee;
  --share-text-color: #fff;
}

main a.share[data-variant='tumblr'] {
  --share-background-color: #35465c;
  --share-text-color: #fff;
}
main a.share[data-variant='buyMeACoffee'] {
  --share-background-color: #ff813f;
  --share-text-color: #fff;
}
main a.share[data-variant='github'] {
  --share-background-color: #def;
  --share-text-color: #fff;
}

main a.share[data-variant='linkedin'] {
  --share-background-color: #0077b5;
  --share-text-color: #fff;
}

main a.share[data-variant='reddit'] {
  --share-background-color: #5f99cf;
  --share-text-color: #fff;
}

main a.share[data-variant='email'] {
  --share-background-color: #777;
  --share-text-color: #fff;
}

@supports (user-select: none) {
  main a.autohead {
    user-select: none;
  }
  main :is(a, button).share {
    user-select: none;
  }
}

dialog {
  border-color: var(--brand-color-light2);
  border-radius: var(--spacing-unit-4);

  max-width: min(100vw, 500px);
  padding: 0;
  text-align: initial;
}
dialog > div {
  padding: var(--spacing-unit-6);
}
dialog > div > h2 {
  margin-block-start: 0;
}
dialog > div > p:first-of-type {
  font-size: var(--small-text-size-1);
  margin-block-end: var(--spacing-unit-5);
}
dialog > div > p:not(:first-of-type) {
  text-align: center;
}
dialog > div > form {
  border-top: var(--spacing-unit-2) dotted var(--mono-color-light3);
  margin-block-start: var(--spacing-unit-6);
  padding-block-start: var(--spacing-unit-5);
  text-align: right;
}
dialog > div > form > button {
  background: #fff;
  border: var(--spacing-unit-2) solid var(--mono-color);
  border-radius: var(--spacing-unit-3);
  color: var(--default-text-color);
  cursor: pointer;
  font-size: var(--small-text-size-2);
  padding: var(--spacing-unit-4) var(--spacing-unit-5);
}
dialog > div > form > button:hover {
  background: var(--mono-color-light5);
}
dialog > div > form > button > svg {
  margin-right: var(--spacing-unit-4);
  vertical-align: middle !important;
}
