@font-face {
  font-family: 'Hack';
  src:
    url('~hack-font/build/web/fonts/hack-regular.woff2?sha=3114f1256') format('woff2'),
    url('~hack-font/build/web/fonts/hack-regular.woff?sha=3114f1256') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hack';
  src:
    url('~hack-font/build/web/fonts/hack-bold.woff2?sha=3114f1256') format('woff2'),
    url('~hack-font/build/web/fonts/hack-bold.woff?sha=3114f1256') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hack';
  src:
    url('~hack-font/build/web/fonts/hack-italic.woff2?sha=3114f1256') format('woff2'),
    url('~hack-font/build/web/fonts/hack-italic.woff?sha=3114f1256') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hack';
  src:
    url('~hack-font/build/web/fonts/hack-bolditalic.woff2?sha=3114f1256') format('woff2'),
    url('~hack-font/build/web/fonts/hack-bolditalic.woff?sha=3114f1256') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
